import { SpinnerDotted } from "spinners-react";
import VCard from "vcard-creator";
import { Fragment, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { appDb } from "../config";
import { doc, getDoc } from "firebase/firestore";
import { Image } from "../components/Image";
import { QRNormal } from "react-qrbtf";
import Icon from "./../assets/img/logo.png";
import ReactCardFlip from "react-card-flip";
import Sheet from "react-modal-sheet";
import { Brand } from "./brand";
import { Header } from "./header";
import { Dialog, Transition } from "@headlessui/react";
import { Links } from "../components/links";
import BusinessCardProfile from "../components/company";
import { QrCodeIcon } from "@heroicons/react/24/outline";
import { browserName, deviceType } from "react-device-detect";
import { createClient } from "@supabase/supabase-js";
import { Other } from "../components/other";
import fileDownload from "js-file-download";
// Create a single supabase client for interacting with your database
const supabase = createClient(
  "https://pppxbumchydernriwxyu.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InBwcHhidW1jaHlkZXJucml3eHl1Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzAyOTgxMTIsImV4cCI6MjA0NTg3NDExMn0.5WiZCJJh8aolfx_TgJiu-z8Ok3qdh2lEjN4mLydMHJ4"
);

function Card() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [team, setTeam] = useState(null);
  const [base64Image, setBase64Image] = useState(null);
  let { id } = useParams();
  const [showBack, setShowBack] = useState(false);
  const [showCard, setShowCard] = useState(false);
  const [codeExpanded, setCodeExpanded] = useState(false);
  const [installOpened, setInstallOpened] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isFooterVisible, setIsFooterVisible] = useState(true);

  const lastScrollY = useRef(0);
  const ticking = useRef(false);

  useEffect(() => {
    const handleScroll = () => {
      if (!ticking.current) {
        window.requestAnimationFrame(() => {
          const currentScrollY = window.scrollY;
          if (currentScrollY > lastScrollY.current + 10) {
            setIsFooterVisible(false);
          } else if (currentScrollY < lastScrollY.current - 10) {
            setIsFooterVisible(true);
          }
          lastScrollY.current = currentScrollY > 0 ? currentScrollY : 0;
          ticking.current = false;
        });
        ticking.current = true;
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (id) {
      getData(id);
      saveEvent({ action_type: "view_profile", target: "company_profile" });
    }
  }, [id]);

  const convertToBase64 = async (url) => {
    if (!url) return null;
    try {
      const response = await fetch(url);
      const blob = await response.blob();

      // Create an image element
      const img = document.createElement("img");
      img.src = URL.createObjectURL(blob);

      return new Promise((resolve, reject) => {
        img.onload = () => {
          // Create a canvas element
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          // Set proper canvas dimensions
          canvas.width = img.width;
          canvas.height = img.height;

          // Draw the image correctly oriented
          ctx.drawImage(img, 0, 0);

          // Convert to base64
          const base64String = canvas
            .toDataURL("image/jpeg", 0.8)
            .split(",")[1];

          // Clean up
          URL.revokeObjectURL(img.src);

          resolve(base64String);
        };
        img.onerror = reject;
      });
    } catch (error) {
      console.error("Error converting image to base64:", error);
      return null;
    }
  };

  useEffect(() => {
    const convertImage = async () => {
      if (data?.profile) {
        const base64String = await convertToBase64(data.profile);
        // For display, we need the full data URL
        setBase64Image(base64String ? `${base64String}` : null);
      }
    };

    convertImage();
  }, [data?.profile]);

  const getData = async (forId) => {
    setLoading(true);
    const docRef = doc(appDb, "public_profiles", forId);
    await getDoc(docRef)
      .then(async (docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data && data.private) {
            save(data);
          } else {
            setData(docSnap.data());
            if (data.team) {
              await getDoc(data.team).then((doc) => {
                if (doc.exists()) {
                  setTeam(doc.data());
                } else {
                }
              });
            }
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const save = async (userInfo) => {
    setIsSaving(true);
    saveEvent({ action_type: "save", target: "save_btn" });
    const card = new VCard();

    if (team !== null) {
      card.setFormat("vcard");
      card.addName(userInfo.general.full_names);
      card.addRole(userInfo.general.job_title);
      card.addJobtitle(userInfo.general.job_title);
      card.addLogoURL(team.brand.logo);
      card.addCompany(team.brand.name);
      card.addURL(team.brand.website);
      if (base64Image) {
        card.addPhoto(base64Image, "JPEG");
      } else {
        card.addPhotoURL(userInfo.profile);
      }
      card.addPhoneNumber(userInfo.general.phone, "WORK");
      card.addEmail(userInfo.general.email);
      card.addAddress(team.brand.address);
      card.setFilename(userInfo.general.full_names);
    } else {
      card.setFormat("vcard");
      card.addName(userInfo.general.full_names);
      card.addRole(userInfo.general.job_title);
      card.addJobtitle(userInfo.general.job_title);
      card.addCompany(userInfo.general.company);
      card.addURL(userInfo.general.website);
      if (base64Image) {
        card.addPhoto(base64Image, "JPEG");
      } else {
        card.addPhotoURL(`${userInfo.profile}`);
      }
      card.addPhoneNumber(userInfo.general.phone, "WORK");
      card.addEmail(userInfo.general.email);
      card.addAddress(userInfo.general.address);
      card.setFilename(userInfo.general.full_names);
    }

    if (userInfo.links) {
      if (userInfo.links.other) {
        userInfo.links.other.forEach((link) => {
          card.addURL(link.url);
        });
      }
      if (userInfo.links.social) {
        userInfo.links.social.forEach((link) => {
          card.addURL(link);
        });
      }
    }

    if (userInfo.other) {
      userInfo.other.forEach((other) => {
        switch (other.type) {
          case "email":
            card.addEmail(other.value);
            break;
          case "phone":
            card.addPhoneNumber(other.value, other.title);
            break;
          case "address":
            card.addAddress(other.value);
            break;
          default:
            break;
        }
      });
    }

    fileDownload(
      card.toString(),
      `${userInfo.general.full_names}.vcf`,
      "text/vcard"
    );
    setInstallOpened(true);
    setIsSaving(false);
  };

  const getUrl = () => {
    const cannonical = document.querySelector("link[rel=cannonical]");
    const url = cannonical ? cannonical.href : document.location.href;
    return url;
  };
  const share = async () => {
    saveEvent({ action_type: "share", target: "share_btn" });
    const shareDetails = {
      url: `${getUrl()}`,
      title: "Digital business card",
      text: `Hello there, here is ${data.general.full_names} business card.`,
    };
    if (navigator.share) {
      try {
        await navigator.share(shareDetails).then(() => {
          setInstallOpened(true);
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const saveEvent = async (event) => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const { error } = await supabase.from("profile_analytics").insert({
      profile_id: id,
      user_id: id,
      action_type: event.action_type,
      target: event.target,
      browser: browserName,
      device_type: deviceType,
      location: timeZone,
      org_id: data?.team?.id || "",
    });
    if (error) {
      console.error(error);
    }
  };

  if (loading)
    return (
      <div className="flex w-screen h-screen">
        <div className="m-auto">
          <SpinnerDotted
            size={50}
            thickness={100}
            speed={100}
            color="#36ad47"
          />
        </div>
      </div>
    );

  if (!data) return <Brand />;

  const makeCode = (small) => (
    <QRNormal
      value={getUrl()}
      className="my-qrcode"
      styles={{ svg: { width: small ? `160px` : "320px" } }}
      type="round"
      size={72}
      opacity={100}
      posType="round"
      otherColor="#808080"
      posColor="#000000"
      icon={Icon}
    />
  );

  return (
    <>
      <div className="min-h-screen bg-gray-100 flex flex-col">
        <div className="mb-64 p-1 ">
          {team && (
            <BusinessCardProfile
              onEvent={saveEvent}
              {...{
                name: data.general.full_names,
                title: data.general.job_title,
                company: team.brand.name,
                tagline: team.brand.tag,
                biography: team.brand.bio,
                website: team.brand.website,
                address: team.brand.address,
                profileImage: data?.profile,
                wallpaperImage: team.brand.wall,
                logoImage: team.brand.logo,
                accentColor: team.theme.accent,
                backgroundColor: team.theme.background,
                primaryColor: team.theme.primary,
                secondaryColor: team.theme.secondary,
              }}
            />
          )}
          {data.general.full_names && team == null && (
            <div className="bg-white border rounded-2xl m-2 shadow-2xl">
              <div className="flex justify-end p-4 absolute right-2">
                <button
                  type="button"
                  className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-full text-sm p-3 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 w-12 h-12"
                  onClick={() => setCodeExpanded(true)}
                >
                  <QrCodeIcon className="w-6 h-6" />
                </button>
              </div>

              <div className="p-1 grid ">
                <div className="max-w-sm rounded-md min-w-full">
                  <div className="grid divide-slate-200 divide-y gap-">
                    <Header
                      imageData={
                        base64Image
                          ? `data:image/jpeg;base64,${base64Image}`
                          : null
                      }
                      names={data?.general?.full_names}
                      job_title={data?.general?.job_title}
                      company={
                        team ? team?.brand?.name : data?.general?.company
                      }
                    />

                    <Sheet
                      isOpen={codeExpanded}
                      onClose={() => setCodeExpanded(false)}
                    >
                      <Sheet.Container>
                        <Sheet.Header />
                        <Sheet.Content className="flex flex-col items-center">
                          <div className="flex justify-center items-center max-w-screen-sm space-y-6">
                            {makeCode()}
                          </div>
                          <div className="flex justify-center items-center max-w-screen-sm space-y-6 pt-12">
                            <p className="text-sm">
                              Scan code to network with{" "}
                              <span className="font-semibold">
                                {data?.general?.full_names}
                              </span>
                            </p>
                          </div>
                          <footer className="fixed bottom-0 left-0 right-0 flex md:items-center md:justify-between mb-0">
                            <div className="w-full">
                              <div className="bg-white w-full p-4 flex justify-center">
                                <span className="text-xs text-gray-500 sm:text-center dark:text-gray-400 ">
                                  Powered by{" "}
                                  <span className="font-semibold text-sm">
                                    <a
                                      href="https://digital.pass-card.app"
                                      className="hover:underline"
                                    >
                                      Pass
                                    </a>
                                  </span>
                                </span>
                              </div>
                            </div>
                          </footer>
                        </Sheet.Content>
                      </Sheet.Container>

                      <Sheet.Backdrop />
                    </Sheet>
                    <Sheet
                      isOpen={installOpened}
                      onClose={() => setInstallOpened(false)}
                      disableDrag={true}
                    >
                      <Sheet.Container>
                        <Sheet.Header>
                          <div className="flex justify-end p-2">
                            <button
                              className="p-2"
                              onClick={() => setInstallOpened(false)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="gray"
                                class="w-6 h-6"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M6 18L18 6M6 6l12 12"
                                />
                              </svg>
                            </button>
                          </div>
                        </Sheet.Header>
                        <Sheet.Content>
                          <Brand />
                        </Sheet.Content>
                      </Sheet.Container>

                      <Sheet.Backdrop />
                    </Sheet>
                  </div>
                </div>
              </div>

              <Links
                email={data.general.email}
                phone={data.general.phone}
                website={data.general.website}
                bio={data.general.bio}
                links={data.links}
                address={data.general.address}
              />

              {data.other && data.other.length > 0 && (
                <div className="max-w-md mx-auto">
                  <Other other={data.other} />
                </div>
              )}
            </div>
          )}
        </div>
        <div className="grow flex" />
        <footer
          style={{
            "--accent-color": team ? team.theme.accent : "slate-700",
            "--background-color": "white",
            "--primary-color": team ? team.theme.primary : "slate-900",
            "--secondary-color": team ? team.theme.secondary : "slate-800",
            backgroundColor: "var(--background-color)",
          }}
          className={`fixed bottom-0 left-0 right-0 flex md:items-center md:justify-between mb-0 bg-white ${
            team ? `border-[${team.theme.accent}]` : "border-slate-300"
          } border-t transition-transform duration-300 ease-in-out rounded-t-xl`}
        >
          <div className="w-full flex flex-col pt-6">
            {data.general.full_names && (
              <div class="flex gap-4 justify-center w-full max-w-md mx-auto pr-4 pl-4">
                <button
                  type="button"
                  style={{
                    borderColor: "var(--accent-color)",
                    borderWidth: "2px",
                    color: "var(--primary-color)",
                  }}
                  className={` hover:text-slate-700 hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center flex-1 justify-center gap-2`}
                  disabled={isSaving}
                  onClick={() => save(data)}
                >
                  {isSaving ? (
                    <div role="status">
                      <svg
                        aria-hidden="true"
                        class="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600"
                        style={{ fill: "var(--accent-color)" }}
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span class="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                        />
                      </svg>
                    </>
                  )}
                  Save contact
                </button>

                <button
                  type="button"
                  className="text-slate-700 bg-white hover:text-slate-700 hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center flex-1 justify-center gap-2"
                  onClick={share}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M7.217 10.907a2.25 2.25 0 1 0 0 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186 9.566-5.314m-9.566 7.5 9.566 5.314m0 0a2.25 2.25 0 1 0 3.935 2.186 2.25 2.25 0 0 0-3.935-2.186Zm0-12.814a2.25 2.25 0 1 0 3.933-2.185 2.25 2.25 0 0 0-3.933 2.185Z"
                    />
                  </svg>
                  Share
                </button>
              </div>
            )}
            <div className="w-full p-4 flex justify-center">
              <span className="text-xs text-gray-500 sm:text-center dark:text-gray-400 ">
                Powered by{" "}
                <span className="font-semibold text-sm">
                  <a
                    href="https://digital.pass-card.app"
                    className="hover:underline"
                  >
                    Pass
                  </a>
                </span>
              </span>
            </div>
          </div>
        </footer>
      </div>
      <Transition appear show={showCard} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10 "
          onClose={() => setShowCard(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto rotate-90">
            <div className="flex min-h-full items-center justify-center text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl text-left align-middle shadow-xl transition-all">
                  <ReactCardFlip
                    isFlipped={showBack}
                    flipDirection="vertical"
                    flipSpeedBackToFront={0.6}
                    flipSpeedFrontToBack={0.6}
                  >
                    <div
                      className="p-0 justify-center items-center max-w-screen-sm"
                      onClick={() => setShowBack(true)}
                    >
                      {data.business && data.business.card_front && (
                        <Image src={data.business.card_front} />
                      )}
                    </div>
                    <div
                      className="justify-center items-center transition-all transition ease-in-out "
                      onClick={() => setShowBack(false)}
                    >
                      {data.business && data.business.card_back && (
                        <Image src={data.business.card_back} />
                      )}
                    </div>
                  </ReactCardFlip>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default Card;
