import React, { useState } from "react";
import {
  PhoneIcon,
  EnvelopeIcon,
  GlobeAltIcon,
  LinkIcon,
  MapPinIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import { SocialIcon } from "react-social-icons";

const Links = ({ email, phone, website, bio, address, links }) => {
  const [expanded, setExpanded] = useState(true);
  const sendEmail = () => {
    window.location.href = `mailto:${email}`;
  };
  const callPhone = () => {
    window.location.href = `tel:${phone}`;
  };
  const openWebsite = (site) => {
    if (site.includes("http") || site.includes("https")) {
      window.open(site, "_blank");
    } else {
      window.open("http://" + site, "_blank");
    }
  };

  return (
    <div className="gap-4 p-4 max-w-md mx-auto">
      <div className="flex items-center justify-center">
        <div className="flex flex-col">
          {email && email !== "" && (
            <button
              className="flex gap-2 py-1 items-center"
              onClick={sendEmail}
            >
              <div className="text-slate-700 flex items-center justify-center ">
                <EnvelopeIcon className="w-5 h-5" />
              </div>
              <div className=" text-left">
                <div className="text-md">{email}</div>
              </div>
              <ChevronRightIcon className="w-4 h-4 text-slate-400" />
            </button>
          )}
          {phone && phone !== "" && (
            <button
              className="flex align-center items-center gap-2 py-1"
              onClick={callPhone}
            >
              <div className="text-slate-700 flex items-center justify-center ">
                <PhoneIcon className="w-5 h-5" />
              </div>
              <div className="text-left">
                <div className="text-md">{phone}</div>
              </div>
              <ChevronRightIcon className="w-4 h-4 text-slate-400" />
            </button>
          )}
          {website && website !== "" && (
            <button
              className="flex gap-2 py-1 items-center"
              onClick={() => openWebsite(website)}
            >
              <div className="text-slate-700 flex items-center justify-center ">
                <GlobeAltIcon className="w-5 h-5" />
              </div>
              <div className="text-left">
                <div className="text-md">{website}</div>
              </div>
              <ChevronRightIcon className="w-4 h-4 text-slate-400" />
            </button>
          )}
          {address && address !== "" && (
            <div className="flex gap-2 py-1 items-center">
              <div className="text-slate-700 flex items-center justify-center ">
                <MapPinIcon className="w-5 h-5" />
              </div>
              <div className="text-left">
                <div className="text-md max-w-xs">{address}</div>
              </div>
            </div>
          )}
        </div>
      </div>
      {(bio || links) && (
        <div
          className={`mt-4 w-full ${
            expanded ? "bg-slate-50" : ""
          } pl-4 pr-4 rounded-xl`}
        >
          {/* <div className="flex items-end justify-end">
            <button
              className="flex gap-1 items-center"
              onClick={() => setExpanded(!expanded)}
            >
              <InformationCircleIcon className="w-5 h-5" />
              {expanded ? "See less" : "See more"}
            </button>
          </div> */}
          {expanded && (
            <div className="flex flex-col items-center divide-y-2">
              <div className="mt-4 mb-4">
                {bio && bio !== "" && (
                  <p className="mt- text-center text-sm tracking-tight text-gray-500">
                    {bio}
                  </p>
                )}
              </div>
              <div className="flex flex-row gap-2 items-start justify-start pt-2 pb-2 flex-wrap">
                {links && links.social && (
                  <>
                    {links.social.map((social) => (
                      <button
                        key={social}
                        className="flex  gap-2 py-2"
                        onClick={() => openWebsite(social)}
                      >
                        <SocialIcon url={social} />
                      </button>
                    ))}
                  </>
                )}
              </div>
              <div className="flex flex-col items-start justify-start pt-2 flex-wrap">
                {links && links.other && (
                  <>
                    {links.other.map((link) => (
                      <button
                        key={link.url}
                        className="flex gap-2 py-2 text-sm"
                        onClick={() => openWebsite(link.url)}
                      >
                        <LinkIcon className="w-4 h-4" /> {link.title}
                      </button>
                    ))}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export { Links };
