import {
  EnvelopeIcon,
  MapPinIcon,
  PhoneIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";

const Other = ({ other }) => {
  const getIcon = (type) => {
    switch (type) {
      case "email":
        return <EnvelopeIcon className="w-5 h-5" />;
      case "phone":
        return <PhoneIcon className="w-5 h-5" />;
      case "address":
        return <MapPinIcon className="w-5 h-5" />;
      default:
        return null;
    }
  };

  const getLinkProps = (type, value) => {
    switch (type) {
      case "email":
        return { href: `mailto:${value}` };
      case "phone":
        return { href: `tel:${value}` };
      default:
        return null;
    }
  };

  return (
    <div className="p-4 flex flex-col gap-4 rounded-xl bg-slate-50 items-center ml-4 mr-4 mb-4">
      <p className="text-slate-700 font-semibold text-sm">
        Additional contact information
      </p>
      <div className="flex flex-col gap-4">
        {other.map((other, index) => {
          const linkProps = getLinkProps(other.type, other.value);
          const ContentWrapper = linkProps ? "a" : "div";

          return (
            <ContentWrapper
              key={other.type + index}
              className="flex items-center gap-4"
              {...linkProps}
            >
              {getIcon(other.type)}
              <div className="flex flex-col gap-0">
                <div className="flex items-center gap-2">
                  <p className="text-slate-700 text-sm">{other.title}</p>
                </div>
                <p className="text-slate-700 text-sm font-semibold">
                  {other.value}
                </p>
              </div>
              {linkProps && (
                <ChevronRightIcon className="w-4 h-4 text-slate-400" />
              )}
            </ContentWrapper>
          );
        })}
      </div>
    </div>
  );
};

export { Other };
