import React from "react";

const Header = ({ imageData, names, job_title, company }) => {
  return (
    <div className="mt-4">
      <div className="flex min-h-full items-center justify-center px-4 sm:px-6 lg:px-8">
        <div className="space-y-">
          <div className="mx-auto h-36 w-36 relative">
            {!imageData ? (
              // Shimmer effect while loading
              <div className="animate-pulse rounded-full bg-slate-200 h-full w-full" />
            ) : (
              <img
                className="h-full w-full shadow-md rounded-full bg-slate-100 object-cover"
                src={imageData}
                alt={`${names} profile`}
              />
            )}
          </div>
          <h4 className="mt-6 text-center text-2xl font-bold tracking-tight text-gray-700">
            {names}
          </h4>
          <p className="mt- text-center text-md font-medium tracking-tight text-gray-500">
            {job_title && <span>{job_title}</span>}
          </p>
          <p className="mt- text-center text-lg font-semibold tracking-tight text-gray-500">
            {company && <span>{company}</span>}
          </p>
        </div>
      </div>
    </div>
  );
};

export { Header };
